import React from 'react';
import Head from 'next/head';
import { isEmpty } from 'lodash';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import GoogleTagManagerHead from './GoogleTagManagerHead';
import { AlternateLangs } from '../models/app';

const MyHead = (props: {
  title?: string;
  keywords?: string;
  description?: string;
  author?: string;
  robots?: string;
  ogType?: string;
  ogUrl?: string;
  ogImage?: string;
  ogImageWidth?: number;
  ogImageHeight?: number;
  alternates?: AlternateLangs[];
  children?: React.ReactNode;
}) => {
  const { t } = useTranslation();
  const { asPath } = useRouter();

  const siteUrl = process.env.SITE_URL || process.env.NEXT_PUBLIC_SITE_URL;
  const canonicalUrl = `${siteUrl}${asPath}`;

  const ENV = process.env.ENV || process.env.NEXT_PUBLIC_ENV;

  const {
    title = t('SITE_TITLE'),
    keywords = '',
    description = t('SITE_DESCRIPTION'),
    author = t('SITE_AUTHOR'),
    ogType = 'website',
    ogUrl = canonicalUrl,
    ogImage = `${siteUrl}/images/logo-meta.jpg`,
    ogImageWidth,
    ogImageHeight,
    alternates = [],
    children
  } = props;

  return (
    <Head>
      <link rel="manifest" href="/manifest.json" />
      <meta name="theme-color" content="#1298C5" />
      <meta name="apple-mobile-web-app-status-bar" content="#1298C5" />

      {title && <title>{title}</title>}
      {title && <meta name="application-name" content={title} />}
      {keywords && <meta name="keywords" content={keywords} />}
      {description && <meta name="description" content={description} />}
      {author && <meta name="author" content={author} />}

      {ENV !== 'production' ? (
        <meta name="robots" content="noindex, nofollow" />
      ) : (
        <meta name="robots" content="all" />
      )}

      <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}

      {/* HrefLang */}
      {!isEmpty(alternates) &&
        alternates.map(({ alternateLang, url }, key) => {
          return (
            <link
              key={key}
              rel="alternate"
              href={`${siteUrl}${url}`}
              hrefLang={alternateLang}
            />
          );
        })}

      <link
        rel="apple-touch-icon"
        sizes="57x57"
        href="/favicon/apple-touch-icon-57x57.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="60x60"
        href="/favicon/apple-touch-icon-60x60.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="72x72"
        href="/favicon/apple-touch-icon-72x72.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="76x76"
        href="/favicon/apple-touch-icon-76x76.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="114x114"
        href="/favicon/apple-touch-icon-114x114.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="120x120"
        href="/favicon/apple-touch-icon-120x120.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="144x144"
        href="/favicon/apple-touch-icon-144x144.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="152x152"
        href="/favicon/apple-touch-icon-152x152.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="192x192"
        href="/favicon/android-icon-192x192.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="96x96"
        href="/favicon/favicon-96x96.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon/favicon-16x16.png"
      />
      <link rel="icon" href="/favicon.ico" type="image/x-icon" />
      <link rel="manifest" href="/favicon/manifest.json" />
      <meta name="theme-color" content="#ffffff" />
      <meta name="msapplication-TileColor" content="#da532c" />
      <meta
        name="msapplication-TileImage"
        content="/favicon/ms-icon-144x144.png"
      />
      <link
        rel="shortcut icon"
        href="/favicon/favicon.ico"
        type="image/x-icon"
      />
      {process.env.GOOGLE_SITE_VERIFICATION && (
        <meta
          name="google-site-verification"
          content={process.env.GOOGLE_SITE_VERIFICATION}
        />
      )}

      {/* --- Open Graph --- */}
      {title && <meta property="og:title" content={title} />}
      {description && <meta property="og:description" content={description} />}
      {ogType && <meta property="og:type" content={ogType} />}
      {ogImage && <meta property="og:image" content={ogImage} />}
      {ogImageWidth && (
        <meta property="og:image:width" content={ogImageWidth.toString()} />
      )}
      {ogImageHeight && (
        <meta property="og:image:height" content={ogImageHeight.toString()} />
      )}
      {ogUrl && <meta property="og:url" content={ogUrl} />}

      {/* --- Twitter --- */}
      {ogImage && <meta name="twitter:image" content={ogImage} />}
      {title && <meta name="twitter:title" content={title} />}
      {description && <meta name="twitter:description" content={description} />}

      {children}

      {/* --- Fonts --- */}
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://cdn.scalapay.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="" />
      <link
        href="https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap"
        rel="stylesheet"
      ></link>
      <script src="https://cdn.scalapay.com/js/scalapay-widget/webcomponents-bundle.js"></script>
      <script src="https://cdn.scalapay.com/js/scalapay-widget/scalapay-widget.js"></script>
      <link rel="stylesheet" href="/font/stylesheet.css" />
      {ENV === 'production' && <GoogleTagManagerHead />}

      {/* Hotjar Tracking Code for https://uynsports.com */}
      {ENV === 'production' && (
        <script
          dangerouslySetInnerHTML={{
            __html: `
             (function(h,o,t,j,a,r){
               h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
               h._hjSettings={hjid:3209332,hjsv:6};
               a=o.getElementsByTagName('head')[0];
               r=o.createElement('script');r.async=1;
               r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
               a.appendChild(r);
             })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`
          }}
        ></script>
      )}
    </Head>
  );
};

export default MyHead;
